import request from "src/utils/request";
import { getOrderStats } from "./orderStats";
import { getOrdersByStatus } from "./getSellerOrders";
import { filterOrdersByCatalog } from "./getAdminOrders";
import { loadingAction } from "src/redux/actions/userActions";
import { setCatalogFilters } from "src/redux/actions/orderActions";
import { getPrintProvidersOrders } from "./getPrintProvidersOrders";

export const setOrderTabName = (userType, tabValue) => {
  if (userType === "Seller") {
    return tabValue === "1"
      ? "all"
      : tabValue === "2"
      ? "on_hold"
      : tabValue === "3"
      ? "in_production"
      : tabValue === "4"
      ? "awaiting_shipment"
      : tabValue === "5"
      ? "fulfilled"
      : tabValue === "6"
      ? "refunded"
      : "";
  } else {
    return tabValue === "1"
      ? "all"
      : tabValue === "2"
      ? "awaiting_approval"
      : tabValue === "3"
      ? "awaiting_shipment"
      : tabValue === "4"
      ? "shipped"
      : tabValue === "5"
      ? "refunded"
      : "";
  }
};

export const getAllOrdersList = (
  dispatch,
  userType,
  page,
  rowsPerPage,
  tabValue
) => {
  let catalog_id = sessionStorage.getItem("selectedCatalogID");
  let tabName = setOrderTabName(userType, tabValue);

  if (userType === "Seller") {
    getOrdersByStatus(dispatch, tabName, page, rowsPerPage);
  } else if (userType === "PrintProvider") {
    getPrintProvidersOrders(dispatch, tabName, page, rowsPerPage);
    getFilterOptions(dispatch, userType);
  } else {
    filterOrdersByCatalog(
      dispatch,
      userType,
      catalog_id && catalog_id !== "undefined" ? [catalog_id] : [],
      page,
      rowsPerPage,
      tabValue
    );
    getFilterOptions(dispatch, userType);
  }
  getOrderStats(dispatch, userType);
};

export const getFilterOptions = async (dispatch, userType) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get(
      userType === "PrintProvider"
        ? "/print-provider/catalogs"
        : "/catalog/admin/list"
    );
    if (res) {
      let catalogs = res?.data?.data?.catalogs?.map((item) => ({
        id: item.id,
        title: item.title,
        no_of_graphics: item.no_of_graphics,
      }));
      dispatch(setCatalogFilters(catalogs));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};
