import React, { useEffect, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import request from "src/utils/request";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { toastify } from "src/utils/toast";
import { bytesToMB } from "src/utils/commonFunctions";
import { setModalState } from "src/redux/actions/modalActions";
import TaxDocument from "src/sections/modals/taxDocument/TaxDocument";
import { useDropzone } from "react-dropzone";
import LibraryImg from "../../../assets/images/library.png";
import { loadingAction } from "src/redux/actions/userActions";

const StyledDropzone = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  textAlign: "center",
  cursor: "pointer",
}));

const UploadTaxDocument = () => {
  const dispatch = useDispatch();
  const [document, setDocument] = useState([]);
  const [fileURL, setFileURL] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  useEffect(() => {
    getTaxDocument();
  }, []);

  const getTaxDocument = async () => {
    dispatch(loadingAction(true));

    try {
      const res = await request.get("/documents");

      if (res) {
        dispatch(loadingAction(false));
        toastify("success", res.data.message);

        console.log(res.data.data, "res.data.data get");
        setFileURL(res?.data?.data[0]?.document);
        setDocument(res?.data?.data[0]?.document);
        setFileUploaded(true);
      }
    } catch (e) {
      setFileUploaded(false);
      dispatch(loadingAction(false));
      toastify("error", e.response.data.message);
    }
  };

  const onDrop = (acceptedFiles) => {
    uploadDocument(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleDelete = (event) => {
    console.log(event, "delete event");
  };

  const uploadDocument = async (document) => {
    let formData = new FormData();
    formData.append("document", document[0]);
    try {
      const res = await request.post("/documents", formData);
      if (res) {
        getTaxDocument();
        toastify("success", res.data.message);
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      setFileUploaded(false);
    }
  };
  
  console.log(fileURL, "fileURL");

  return (
    <div>
      {fileUploaded ? (
        <>
          <Paper variant="outlined" sx={{ width: "50%" }}>
            <List>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() =>
                    dispatch(
                      setModalState(<TaxDocument document={fileURL} />, true)
                    )
                  }
                >
                  <ListItemIcon>
                    <FolderIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                  // primary={document[0]?.name}
                  // secondary={`${bytesToMB(document[0]?.size)} MB - ${format(
                  //   document[0]?.lastModifiedDate,
                  //   "dd MMM yyyy h:mm a"
                  // )}`}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </>
      ) : (
        <StyledDropzone
          elevation={3}
          {...getRootProps()}
          sx={{ height: "30vh" }}
        >
          <input {...getInputProps()} multiple />
          <img src={LibraryImg} alt="library" style={{ width: 120 }} />
          <div>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Drag Your Files Here
            </Typography>
          </div>
        </StyledDropzone>
      )}
    </div>
  );
};

export default UploadTaxDocument;
